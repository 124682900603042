.grid-gallery {
  width: 100%;
}

.grid-gallery-inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.gallery-item {
  display: block;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .grid-gallery-inner {
    grid-template-columns: 1fr;
  }
}
