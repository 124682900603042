@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular */
@font-face {
  font-family: "goldbold";
  src: url("/public/fonts/gobold/Gobold_Regular.otf") format("opentype");
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

/* Thin */
@font-face {
  font-family: "goldbold";
  src: url("/public/fonts/gobold/Gobold_Thin.otf") format("opentype");
  font-weight: 100; /* Thin weight */
  font-style: normal;
}

@font-face {
  font-family: "goldbold";
  src: url("/public/fonts/gobold/Gobold_Regular.otf") format("opentype");
  font-weight: 900; /* Normal weight */
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: url("/public/fonts/Inter/Inter-VariableFont_opsz,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "dm_sans";
  src: url("/public/fonts/DM_Sans/DMSans-VariableFont_opsz\,wght.ttf")
    format("truetype");
  font-weight: 700;
}

h1 {
  font-family: "goldbold", sans-serif;
  font-weight: 900 !important; /* Bold */
}

h2 {
  font-family: "inter", sans-serif;
  font-weight: 800 !important; /* Bold */
}

h3,
h4,
h5,
h6 {
  font-family: "goldbold", sans-serif;
  font-weight: 400 !important; /* Bold */
}

p {
  font-family: "inter", sans-serif;
  font-weight: 300;
}

body {
  margin: 0;
  font-family: "inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F4F6;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
